import { useState, useMemo } from "react";
import { useUser, SignInButton } from "@clerk/nextjs";
import { useToast } from "@/hooks/use-toast";
import { useQuery } from "convex/react";
import { api } from "@/convex/_generated/api";
import { Button } from "@/components/ui/button";
import { cn } from "@/lib/utils";
import { Check, ShoppingCart, Database, ChevronUp, ChevronDown, LogIn, Loader2 } from "lucide-react";
import { createStripeCheckoutSession } from "@/app/actions/createStripeCheckoutSession";
import { Id } from "@/convex/_generated/dataModel";
import JsonTable from "./JsonTable";

interface PurchaseButtonProps {
  eventId: Id<"events">;
  price: number;
  sampleData?: string;
  variant?: "default" | "large";
  className?: string;
  showPrice?: boolean;
}

export default function PurchaseButton({ 
  eventId, 
  price, 
  variant = "default", 
  className,
  showPrice = true
}: PurchaseButtonProps) {
  const [isProcessing, setIsProcessing] = useState(false);
  const { user, isSignedIn } = useUser();
  const { toast } = useToast();
  
  // Prevent purchase if user is the seller
  const isSellerQuery = useQuery(api.users.isEventSeller, { 
    eventId: eventId, 
    userId: user?.id || "" 
  });

  if (isSellerQuery) {
    return (
      <div className="w-full px-4 py-2.5 bg-gray-50 text-gray-600 rounded-lg text-center border border-gray-200">
        <div className="flex items-center justify-center gap-1">
          <Check className="w-4 h-4 text-gray-500" />
          <span className="font-medium text-sm">You're the seller</span>
        </div>
      </div>
    );
  }

  const handlePurchase = async () => {
    if (!isSignedIn) {
      // We don't need to do anything here as the SignInButton will handle the sign-in flow
      return;
    }

    setIsProcessing(true);
    try {
      // Get the primary email address
      const userEmail = user.primaryEmailAddress?.emailAddress || "";
      
      const { sessionUrl } = await createStripeCheckoutSession({
        eventId,
        userEmail, // Pass email to the server action
      });

      if (!sessionUrl) throw new Error("No session URL returned");
      window.location.href = sessionUrl;
    } catch (error) {
      console.error("Purchase error:", error);
      toast({
        title: "Error",
        description: "Failed to process purchase. Please try again.",
        variant: "destructive",
      });
    } finally {
      setIsProcessing(false);
    }
  };

  // Return different button based on sign-in status
  if (!isSignedIn) {
    return (
      <SignInButton mode="modal">
        <button
          className={cn(
            "w-full flex items-center justify-center gap-2 font-medium transition-colors",
            variant === "large"
              ? "py-3 px-6 text-base"
              : "py-2 px-4 text-sm",
            "bg-blue-600 hover:bg-blue-700 text-white rounded-lg",
            className
          )}
        >
          {showPrice ? `Purchase for £${price}` : "Purchase"}
        </button>
      </SignInButton>
    );
  }

  return (
    <button
      onClick={handlePurchase}
      disabled={isProcessing}
      className={cn(
        "w-full flex items-center justify-center gap-2 font-medium transition-colors",
        variant === "large"
          ? "py-3 px-6 text-base"
          : "py-2 px-4 text-sm",
        "bg-blue-600 hover:bg-blue-700 text-white rounded-lg",
        isProcessing ? "opacity-70 cursor-not-allowed" : "",
        className
      )}
    >
      {isProcessing ? (
        <>
          <Loader2 className="w-4 h-4 animate-spin" />
          Processing...
        </>
      ) : (
        showPrice ? `Purchase for £${price}` : "Purchase"
      )}
    </button>
  );
}

function PreviewSection({ 
  sampleData, 
  showPreview, 
  setShowPreview 
}: { 
  sampleData: string; 
  showPreview: boolean; 
  setShowPreview: (show: boolean) => void;
}) {
  const parsedData = useMemo(() => {
    try {
      return typeof sampleData === 'string' ? JSON.parse(sampleData) : sampleData;
    } catch (e) {
      return null;
    }
  }, [sampleData]);

  if (!parsedData) return null;

  return (
    <div className="mt-4">
      <button
        onClick={() => setShowPreview(!showPreview)}
        className="w-full flex items-center justify-between p-3 text-sm text-gray-700 hover:bg-gray-50 rounded-lg border border-gray-200 transition-colors"
      >
        <span className="flex items-center gap-2">
          <Database className="w-4 h-4" />
          Sample Data Preview
        </span>
        {showPreview ? <ChevronUp className="w-4 h-4" /> : <ChevronDown className="w-4 h-4" />}
      </button>
      
      {showPreview && (
        <div className="mt-3">
          <JsonTable 
            data={parsedData}
            
          />
        </div>
      )}
    </div>
  );
} 