"use client";

import { api } from "@/convex/_generated/api";
import { useQuery } from "convex/react";
import { Id } from "@/convex/_generated/dataModel";
import {
  Ticket,
  Check,
  XCircle,
  Database,
  Mail,
  Phone,
  CheckCircle2,
  ChevronDown,
  ChevronUp,
  Users,
  DollarSign,
  ShieldCheck,
  Tag,
  Clock,
} from "lucide-react";
import { useUser } from "@clerk/nextjs";
import { useRouter, useSearchParams } from "next/navigation";
import Link from "next/link";
import { useToast } from "@/hooks/use-toast";
import PurchaseButton from "./PurchaseButton";
import { useState, useMemo } from "react";
import JsonTable from "./JsonTable";
import { formatLastUpdated } from "@/lib/utils";

interface EventCardProps {
  eventId: Id<"events">;
}

export default function EventCard({ eventId }: EventCardProps) {
  // All hooks must be called at the top level
  const { user } = useUser();
  const router = useRouter();
  const searchParams = useSearchParams();
  const { toast } = useToast();
  const [showJsonPreview, setShowJsonPreview] = useState(false);
  
  const event = useQuery(api.events.getById, { eventId });
  const availability = useQuery(api.events.getEventAvailability, { eventId });
  const userTicket = useQuery(api.tickets.getUserTicketForEvent, {
    eventId,
    userId: user?.id || "",
  });

  // Parse sample data
  const sampleData = useMemo(() => {
    try {
      if (!event?.sampleData) return [];
      
      const parsed = typeof event.sampleData === 'string' 
        ? JSON.parse(event.sampleData)
        : event.sampleData;
        
      return Array.isArray(parsed) ? parsed : [parsed];
    } catch (e) {
      console.error("Error parsing sample data:", e);
      return [];
    }
  }, [event?.sampleData]);

  if (!event || !availability) {
    return null;
  }

  const hasAccess = userTicket?.status === "valid";
  const hasPurchased = !!userTicket;

  // Calculate sales information
  const salesCount = event.purchaseCount || 0;
  const hasSales = salesCount > 0;

  return (
    <div className="bg-white rounded-xl border border-gray-200 hover:border-blue-300 transition-all hover:shadow-lg overflow-hidden flex flex-col h-full">
      {/* Card Header - Now includes price */}
      <div className="p-5 pb-3 border-b border-gray-100 bg-gradient-to-r from-blue-50 to-white">
        <div className="flex items-center justify-between mb-2">
          <div className="flex items-center gap-2">
            <span className="text-xs font-medium bg-blue-100 text-blue-700 px-2.5 py-1 rounded-full">
              {event.category}
            </span>
            
            {/* Show sales count badge if there are sales */}
            {hasSales && (
              <span className="text-xs font-medium bg-amber-100 text-amber-700 px-2.5 py-1 rounded-full flex items-center gap-1">
                <Users className="w-3 h-3" /> {salesCount} sold
              </span>
            )}
          </div>
          {/* Enhanced Price badge with GBP formatting - whole numbers only */}
          <span className="text-sm font-semibold bg-green-100 text-green-800 px-3 py-1.5 rounded-full flex items-center gap-1 border border-green-200 shadow-sm">
            {typeof event.price === 'number' 
              ? `£${Math.round(event.price)}`
              : `£${event.price}`
            }
          </span>
        </div>
        <h3 className="text-xl font-bold text-gray-900 line-clamp-1">{event.title}</h3>
      </div>
      
      {/* Card Body */}
      <div className="p-5 pt-3 flex-grow flex flex-col">
        {/* Description */}
        <p className="text-sm text-gray-600 line-clamp-2 mb-4">{event.description}</p>
        

        {/* Key Stats - Improved layout */}
        <div className="grid grid-cols-2 gap-4 py-3 border-y border-gray-100 mb-4">
          <div className="flex items-center gap-2">
            <Database className="w-4 h-4 text-indigo-500" />
            <div>
              <p className="text-xs text-gray-500">Leads</p>
              <p className="text-base font-semibold text-gray-900">
                {event.leadCount.toLocaleString()}
              </p>
            </div>
          </div>
          <div className="flex items-center gap-2">
            <Clock className="w-4 h-4 text-indigo-500" />
            <div>
              <p className="text-xs text-gray-500">Updated</p>
              <p className="text-base font-semibold text-gray-900">
                {formatLastUpdated(event.lastUpdated)}
              </p>
            </div>
          </div>
        </div>

        {/* Data Quality Badges - Enhanced styling */}
        <div className="flex flex-wrap gap-2 mb-4">
          {event.containsEmails && (
            <span className="inline-flex items-center gap-1 px-2.5 py-1 bg-blue-100 text-blue-700 text-xs rounded-md font-medium">
              <Mail className="w-3 h-3" /> Emails
            </span>
          )}
          {event.containsVerifiedEmails && (
            <span className="inline-flex items-center gap-1 px-2.5 py-1 bg-green-100 text-green-700 text-xs rounded-md font-medium">
              <CheckCircle2 className="w-3 h-3" /> Verified
            </span>
          )}
          {event.containsPhones && (
            <span className="inline-flex items-center gap-1 px-2.5 py-1 bg-purple-100 text-purple-700 text-xs rounded-md font-medium">
              <Phone className="w-3 h-3" /> Phones
            </span>
          )}
        </div>
        
        {/* Card Actions */}
        <div className="mt-auto">
          {/* Seller indicator */}
          {event.userId === user?.id ? (
            <div className="w-full px-3 py-2 bg-gray-50 text-gray-700 rounded-lg text-center border border-gray-200 text-sm">
              <div className="flex items-center justify-center gap-1.5">
                <Check className="w-3.5 h-3.5 text-gray-500" />
                <span className="font-medium">You are the seller</span>
              </div>
            </div>
          ) : (
            /* Enhanced Purchase Section */
            <div className="space-y-3">
              {/* Trust indicators */}
              <div className="flex items-center justify-between">
                <div className="flex items-center gap-1.5">
                  <ShieldCheck className="w-4 h-4 text-blue-500" />
                  <span className="text-sm font-medium text-gray-600">Verified Data</span>
                </div>
              </div>
              
              {/* Action Buttons - Improved CTA */}
              <div className="grid grid-cols-2 gap-2">
                <Link 
                  href={`/lists/${eventId}`}
                  className="block text-center bg-white hover:bg-gray-50 text-gray-700 px-3 py-2.5 rounded-lg font-medium border border-gray-200 transition-colors text-sm flex items-center justify-center"
                >
                  View Details
                </Link>
                
                {!event.is_cancelled && !hasPurchased ? (
                  <PurchaseButton 
                    eventId={eventId}
                    price={event.price}
                    variant="default"
                    showPrice={false}
                    className="h-full min-h-[40px] bg-blue-600 hover:bg-blue-700 text-white rounded-lg font-medium transition-colors text-sm shadow-sm"
                  />
                ) : hasAccess ? (
                  <button
                    onClick={() => router.push(`/tickets/${userTicket._id}`)}
                    className="bg-green-600 hover:bg-green-700 text-white px-3 py-2.5 rounded-lg font-medium transition-colors text-sm shadow-sm flex items-center justify-center"
                  >
                    View Data
                  </button>
                ) : (
                  <div className="bg-gray-100 text-gray-500 px-3 py-2.5 rounded-lg font-medium text-center text-sm flex items-center justify-center">
                    Unavailable
                  </div>
                )}
              </div>
            </div>
          )}

          {/* Status indicators */}
          {event.is_cancelled && (
            <div className="mt-2 p-2 bg-red-50 rounded-lg border border-red-100">
              <div className="flex items-center text-red-700">
                <XCircle className="w-3.5 h-3.5 mr-1.5" />
                <span className="text-xs font-medium">This list has been cancelled</span>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
