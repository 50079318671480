"use client";

import React from 'react';

interface JsonTableProps {
  data: any[];
}

export default function JsonTable({ data }: JsonTableProps) {
  if (!Array.isArray(data) || data.length === 0) {
    return <div className="p-4 text-gray-500">No sample data available</div>;
  }

  // Get all unique keys from all objects
  const allKeys = Array.from(
    new Set(
      data.flatMap(obj => Object.keys(obj))
    )
  );

  // Show first 2 rows only
  const displayData = data.slice(0, 2);

  return (
    <div className="overflow-x-auto">
      <table className="min-w-full divide-y divide-gray-200">
        <thead className="bg-gray-50">
          <tr>
            {allKeys.map((key) => (
              <th
                key={key}
                className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
              >
                {key}
              </th>
            ))}
          </tr>
        </thead>
        <tbody className="bg-white divide-y divide-gray-200">
          {displayData.map((item, index) => (
            <tr key={index}>
              {allKeys.map((key) => (
                <td
                  key={key}
                  className="px-6 py-4 whitespace-nowrap text-sm text-gray-500"
                >
                  {typeof item[key] === 'object' 
                    ? JSON.stringify(item[key])
                    : String(item[key] ?? '')}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
} 